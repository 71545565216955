

import dayjs from "dayjs";

import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";

export function generateTimeOptions(
  startTime: string,
  endTime: string,
  intervalMinutes: number
): SelectOptions[] {
  const options: SelectOptions[] = [];
  const currentTime = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);

  while (currentTime <= end) {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    options.push({
      id: `${formattedHours}:${formattedMinutes} ${ampm}`,
      name: `${formattedHours}:${formattedMinutes} ${ampm}`,
    });

    currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
  }

  return options;
}

export function generateDateOptions(): SelectOptions[] {
  const dateOptions: SelectOptions[] = [];
  const today = dayjs();

  for (let i = 0; i <= 15; i++) {
    const currentDate = today.add(i, "day");
    const formattedDate = currentDate.format("ddd, D MMM YYYY");

    dateOptions.push({
      id: formattedDate,
      name: formattedDate,
    });
  }

  return dateOptions;
}


/**
 * Check if the given date has passed midnight in UTC+8
 */
export const isNewDay = (date: Date): boolean => {




  // Convert current time to UTC+8
  const isNewDay = dayjs(date).utcOffset(8).isBefore(dayjs().utcOffset(8), "day");

  return isNewDay
};

/**
* Convert timestamp to relative time string (e.g., "2 hours ago")
*/
export const getTimeAgo = (timestamp: string): string => {
  const now = new Date();
  const activityTime = new Date(timestamp);
  const diffInMinutes = Math.floor(
    (now.getTime() - activityTime.getTime()) / (1000 * 60)
  );

  if (diffInMinutes < 1) return "just now";
  if (diffInMinutes === 1) return "1 minute ago";
  if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours === 1) return "1 hour ago";
  if (diffInHours < 24) return `${diffInHours} hours ago`;

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays === 1) return "1 day ago";
  return `${diffInDays} days ago`;
};

